import { Component, OnInit } from '@angular/core';
import { SharedDataService } from '../pages/service/shared-data.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  switchLang!: string;
  logoHref!: string;

  constructor(private data:SharedDataService) {
    this.data.langFromUrl.subscribe(lang=> {
      this.logoHref= lang + "/game-selection";
    });

   }

  ngOnInit(): void {
  }

}
