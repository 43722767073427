import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { SharedDataService } from './pages/service/shared-data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'escape-room-game';
  pageName: string | undefined;

  constructor(private router:Router, private data:SharedDataService, private _router : ActivatedRoute){
  }
}

