import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, NavigationEnd, Router, RouterEvent, RouterStateSnapshot, UrlTree } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { SharedDataService } from "../shared-data.service";

@Injectable({providedIn:"root"})

export class AuthGuard implements CanActivate{
    supportedLanguage=["en", "de"];
    defaultLanguage = "en";

    constructor(private router:Router, private data:SharedDataService, private _router:ActivatedRoute, public translate: TranslateService){
     }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
   
      this.router.events.subscribe((event:RouterEvent| any)=>{
            if(event instanceof NavigationEnd){
              var splittedUrl = event.url.split('/');
              var pageLang = "";
              if(splittedUrl.length > 1 && splittedUrl[1] != ""){
                pageLang = splittedUrl[1];
                
              if(this.supportedLanguage.includes(pageLang)){
                this.data.langFromUrl.next(pageLang);
              }
              else{
                var url = "";
                splittedUrl[1] = this.defaultLanguage;
                splittedUrl.forEach(x=> {
                  url += x + "/";
                });
                this.router.navigate([url.substring(0, url.length - 1)]);
              }
            }
            }
          });
        return true;
    }  
}