import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GameCodeValidation } from 'src/app/models/gameCodeValidation';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CodeValidationService {
    constructor(private http: HttpClient) { }

    validateGameCode(gameCode: string) {
        return this.http.post<GameCodeValidation>(environment.backends.gameCodeValidation, {
            "gameCode": gameCode
        });
    }

}
