<div class="tips">
  <button *ngFor="let tip of tippsData; index as i" [disabled]="!tip.IsRedeemed" (click)="getTipp(tip)" type="button" class="btn btn-secondary p-2 mt-2"
      popover={{tip.Text}} popoverTitle="{{ 'Tip.Tip' | translate }} {{tip.TipNo}}" triggers="focus"
      placement="bottom">
      {{ 'Tip.Tip' | translate }} {{tip.TipNo}}
    </button>
    <button type="button" [disabled]="solutionDisplay == false" (click)="getTipSolution()"  class="btn btn-secondary p-2 mt-2"
      popover={{solution}} popoverTitle="{{ 'Tip.Solution' | translate }}" triggers="focus"
      placement="bottom">
      {{ 'Tip.Solution' | translate }}
    </button>
  </div>