import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
import { AuthGuard } from './pages/service/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'en/game-selection',
      },      
      {
        path: ':lang/game-selection',
        canActivate: [AuthGuard],
        pathMatch: 'full',
      loadChildren : ()=>import('./pages/game-selection/game-selection.module').then(m=>m.GameSelectionModule)
      },
      {
          path: ':lang/game-selection/:gamecode',
          canActivate: [AuthGuard],
          pathMatch: 'full',
        loadChildren : ()=>import('./pages/game-selection/game-selection.module').then(m=>m.GameSelectionModule)
        },
        {
            path: ':lang/game/:gamecode',
            canActivate: [AuthGuard],
            pathMatch: 'full',
            loadChildren: () => import('./pages/game/game.module').then(m => m.GameModule)
        }
    ]
  },  
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }