import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  private gameCode = new BehaviorSubject<string>('default message');
  currentGameCode = this.gameCode.asObservable();

  private RoomId = new BehaviorSubject<string>('default message');
  currentRoomId = this.RoomId.asObservable();

  private gameInternalName = new BehaviorSubject<string>('');
  currentGameInternalName = this.gameInternalName.asObservable();

  private lastedSolvedRoomId = new BehaviorSubject<string>('');
  currentLastedSolvedRoomId = this.lastedSolvedRoomId.asObservable();

  constructor() { }

  changeGameInternalName(message: string) {
    this.gameInternalName.next(message)
  }

  changelastedSolvedRoomId(message: string) {
    this.lastedSolvedRoomId.next(message)
  }

  changeRoomId(message: string) {
    this.RoomId.next(message)
  }

  changeGameCode(message: string) {
    this.gameCode.next(message)
  }
  
  langFromUrl = new BehaviorSubject('en');
}
