import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-image',
  templateUrl: './game-type-image.component.html',
  styleUrls: ['./game-type-image.component.scss']
})
export class GameTypeImageComponent implements OnInit {

  @Input() data: any;
  answerSubmissionForm: FormGroup;
  submitAnswer: any;
  introText: any;
  @Output() messageEvent = new EventEmitter<any>();
  answer: any;
  introAudio: any;
  tips: any;

  constructor(private fb: FormBuilder) {

    this.answerSubmissionForm = this.fb.group({
      answer: [null, Validators.required]
    })
  }

  ngOnInit(): void {
    this.introText = this.data.introText;
    this.introAudio = this.data.introAudioFile
  }

  submit() {
    this.answer = this.answerSubmissionForm.value['answer']
    this.messageEvent.emit(this.answer)
  }
}
