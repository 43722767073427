import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedDataService } from 'src/app/pages/service/shared-data.service';
import { TippData } from 'src/app/models/tippData';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tipps',
  templateUrl: './tipps.component.html',
  styleUrls: ['./tipps.component.scss']
})
export class TippsComponent implements OnInit {
  @Input() incomingTipps: any;

  solutionDisplay = false;
  solution = "Your Solution will be here soon";
  gameCode: any;
  roomId: any;
  tipps: any;
  langStatus: any;
  tippsData!: TippData[];

  constructor(private http: HttpClient, private data: SharedDataService, public translate: TranslateService) {
    this.data.langFromUrl.subscribe(lang => {
      this.langStatus = lang;
    })
  }

  ngOnInit(): void {
    this.data.currentGameCode.subscribe(message => this.gameCode = message)
    this.data.currentRoomId.subscribe(message => this.roomId = message)
    this.http.get<TippData[]>(environment.backends.tipLink + "/game/"+ this.gameCode + '/room/' + this.roomId + '/tipps/alltipps?lang=' + this.langStatus).subscribe(
      data => {
        this.tippsData = data;
        if (this.tippsData[this.tippsData.length - 1].IsRedeemed == true) {
          this.solutionDisplay = true;
          this.getTipSolution();
        }
      })
  }
  getTipp(tippModel: TippData) {
    this.http.get<any>(environment.backends.tipLink + "/game/"+ this.gameCode + '/room/' + this.roomId + '/tipps/' + (tippModel.TipNo - 1) + "?lang=" + this.langStatus).subscribe(data => {

      tippModel.Text = data.text;
      var nextTipp = this.tippsData.filter((obj) => {
        return obj.TipNo == tippModel.TipNo + 1
      })
      if (nextTipp.length > 0) {
        nextTipp[0].IsRedeemed = true;
      }
    })
    if (tippModel.TipNo == this.tippsData.length) {
      this.solutionDisplay = true;
      this.getTipSolution();
    }
  }
  getTipSolution() {
    this.http.get<any>(environment.backends.tipLink + "/game/"+ this.gameCode + '/room/' + this.roomId + '/tipps/solution').subscribe(data => {
      this.solution = data
    })
  }
}
