import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedDataService } from '../pages/service/shared-data.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  switchLang : any;
  browserLang: any;
  logoHref:any;
  constructor(public translate: TranslateService, private data:SharedDataService, private router : Router) {
    
    translate.addLangs(['de', 'en']);
    translate.setDefaultLang('en');
    translate.use('en');
    this.browserLang = translate.getDefaultLang();
    this.translate.use(this.browserLang.match(/de|en/)? this.browserLang : 'en')

    this.data.langFromUrl.subscribe(lang=> {
      this.translate.use(lang);
      this.logoHref= lang + "/game-selection";
    });
   }
   selectedLanguage(lang:any){
    this.data.langFromUrl.next(lang);
    this.translate.use(lang);
    var splittedUrl = window.location.pathname.split('/');
    if(splittedUrl.length > 1 && splittedUrl[1] != ""){
      var url = "";
      splittedUrl[1] = lang;
      splittedUrl.forEach(x=> {
        url += x + "/";
      });
      this.router.navigate([url.substring(0, url.length - 1)]);
    }
   }

  ngOnInit(): void {
  }

}
