
<div class="dynamic">
    <app-tipps [incomingTipps]="tips"></app-tipps>
    <img src="https://www.w3schools.com/html/pic_trulli.jpg" alt="">
    <h5>{{introText}}</h5>
    <form autocomplete="off" [formGroup]="answerSubmissionForm" (ngSubmit)="submit()" >
        <div class="form-group form-group--lg">
            <input type="text" class="form-control" name="answer" placeholder="enter answer" formControlName="answer">
        </div>
        <input type="submit" class="btn btn-primary btn-lg btn-block p-2 mt-4" value="Submit">
    </form>
  </div>
  <app-intro-audio [incomingAudio]="introAudio"  *ngIf="introAudio != null"></app-intro-audio>