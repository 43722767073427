<!-- Footer
================================================== -->
<footer id="footer" class="footer ">
    <!-- Footer Copyright -->
        <div class="footer-copyright">
            <div class="container">
    
                <!-- Logo - Image Based -->
                <div class="footer__logo footer__logo--img">
                    <a href={{logoHref}}><img src="../../assets/img/footer-logo.png" srcset="../../assets/img/footer-logo@2x.png 2x" alt="Escapium Logo"></a>
                </div>
                <!-- Logo - Image Based / End -->
    
                <div class="footer-copyright__txt mt-2">
                    {{'Footer.Copyright' | translate}}&nbsp;|&nbsp; {{'Footer.Rights' | translate}}
                </div>
            </div>
        </div>
        <!-- Footer Copyright / End -->
    </footer>
<!-- Footer / End -->