// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  backends: {
    hubConnectionUrl: "https://escape-room-broker-7wdyxozosa-ew.a.run.app/game",
    gameCodeValidation: "https://europe-west1-escape-room-test-330114.cloudfunctions.net/gamecodevalidation",
    tipps: "",
    tipLink: "https://europe-west1-escape-room-test-330114.cloudfunctions.net/escape-tipps",
  },

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
