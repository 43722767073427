import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CodeValidationService } from '../service/rest-endpoints/code-validation.service';
import { SharedDataService } from '../service/shared-data.service';

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss']
})


export class GameComponent implements OnInit {
  gameCode: any;
  introText: string | undefined;
  introAudio: any;
  roomComponent: any;
  lastSolvedId: string | undefined;
  gameConfig: any;
  i!: number;
  childData: any;
  solution: any;
  roomId: any;

  constructor(private _router: Router, private router: ActivatedRoute, private data: SharedDataService, private codeValidationService: CodeValidationService) {
  }

  async ngOnInit() {
    this.router.params.subscribe(params => {
      this.gameCode = params['gamecode'];
    });
    this.codeValidationService.validateGameCode(this.gameCode).subscribe(
      res => {
        this.data.changeGameInternalName(res.internalName);
        this.data.changeGameCode(this.gameCode);
        this.data.changelastedSolvedRoomId(res.lastSolvedRoomId);
        this.loadGame();
      });
    this.data.currentRoomId.subscribe(message => this.roomId = message)

    await this.data.currentLastedSolvedRoomId.subscribe(x => this.lastSolvedId = x);
  }

  loadRoom() {

    if (this.lastSolvedId) {
      var currentRoom = this.gameConfig.rooms.filter((data: any) => {
        return data.id === this.lastSolvedId;
      })[0];

      this.dynamicComponent(currentRoom);
      this.i = this.gameConfig.rooms.findIndex(((x: { component: any; }) => x.component == currentRoom.component));
      this.startButton();
    }

    // load intro
    this.introText = this.gameConfig.intro.introText;
    this.introAudio = this.gameConfig.intro.introAudioFile;
    this.i = 0
  }

  async loadGame() {
    var internalGameName = "";
    await this.data.currentGameInternalName.subscribe(x => internalGameName = x);

    if (!this.gameCode || !internalGameName) {
      this._router.navigateByUrl('/game-selection')
    }

    await import("../games/assets/" + internalGameName + ".json").then(data => {
      this.gameConfig = data;

      this.loadRoom();
    });
  }

  startButton() {
    this.dynamicComponent(this.gameConfig.rooms[this.i])
    this.introAudio = null;
  }

  nextRoom() {
    this.i = this.i + 1;
    this.dynamicComponent(this.gameConfig.rooms[this.i])
  }

  receiveAnswer($event: any) {
    this.solution = $event;
    this.nextRoom()
  }

  dynamicComponent(array: any) {
    this.childData = array;
    this.roomComponent = array.component;
    this.roomId = array.id;
    this.data.changeRoomId(this.roomId);
  }
}