import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-intro-audio',
  templateUrl: './intro-audio.component.html',
  styleUrls: ['./intro-audio.component.scss'],
})
export class IntroAudioComponent implements OnInit {
  audio: HTMLAudioElement | undefined;
  @Input() incomingAudio: any;
  constructor() { this.audio?.pause(); }

  async ngOnInit() {
    this.audio?.pause();
    this.audio = new Audio(this.incomingAudio);
    this.audio.play();
  }

  ngOnDestroy() {
    this.audio?.pause();
  }

}
