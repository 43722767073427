import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GameComponent } from './game.component';
import { ReactiveFormsModule } from '@angular/forms';
import { GameRoutingModule } from './game-routing.module';
import { GameTypeImageComponent } from '../games/game-types/game-type-image/game-type-image.component';
import { GameTypeVideoComponent } from '../games/game-types/game-type-video/game-type-video.component';
import { IntroAudioComponent } from '../games/shared/intro-audio/intro-audio.component';
import { TippsComponent } from '../games/shared/tipps/tipps.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    GameComponent,
    GameTypeImageComponent,
    GameTypeVideoComponent,
    IntroAudioComponent,
    TippsComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    GameRoutingModule,
    PopoverModule.forRoot(),
    TranslateModule
  ]
})
export class GameModule { }
