<div class="dynamic">
  <ng-container [ngSwitch]="roomComponent">
    <app-image (messageEvent)="receiveAnswer($event)" [data]="childData" *ngSwitchCase="'ImageComponent'"></app-image>
    <app-video (messageEvent)="receiveAnswer($event)" [data]="childData" *ngSwitchCase="'VideoComponent'"></app-video>
  </ng-container>
  <h5 *ngIf="roomComponent == null">{{introText}}</h5>
  <div>
    <input *ngIf="roomComponent == null" (click)="startButton()" type="button"
      class="btn btn-primary btn-lg btn-block p-2 mt-4" value="{{'Game.GameStartButton' | translate}}">
  </div>
</div>
<app-intro-audio [incomingAudio]="introAudio"  *ngIf="introAudio != null"></app-intro-audio>