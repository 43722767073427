<!-- navbar================================================== -->
<header id="header" class="header">
  <div class="header__logo header__logo--img">
    <a class="navbar-brand" href="{{logoHref}}" ><img src="../assets/img/logo.png" alt=""></a>
  </div>
      <nav class="navbar navbar-expand navbar-light">
        <div class="lang-align" >
          <a [class.active]="switchLang== 'de'" class="lang" (click)="selectedLanguage('de')"
          >German</a>
           <b>&nbsp;|&nbsp;</b>
          <a [class.active]="switchLang== 'en'" class="lang" (click)="selectedLanguage('en')"
          >English</a>   
        </div>
  </nav>
</header>